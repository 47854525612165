import { useFetcher } from "@remix-run/react"
import { useEffect, useState } from "react"
import { FeaturedTags } from "~/utils/search/tag-suggestions-data"
import { Button } from "~/components/ui/button"
import { ChevronRightIcon } from "@radix-ui/react-icons"

interface SearchPreviewProps {
  term: string
  onSelectSuggestion: (suggestion: string) => void
}

export function SearchPreview({ term, onSelectSuggestion }: SearchPreviewProps) {
  const fetcher = useFetcher<{ names: string[] }>()
  const [matchingTags, setMatchingTags] = useState<string[]>([])

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetcher.load(`/api/tag-suggestions?term=${encodeURIComponent(term)}`)
    }, 300)
    return () => clearTimeout(debounceTimeout)
  }, [term, fetcher])

  useEffect(() => {
    if (fetcher.data) {
      setMatchingTags(fetcher.data.names)
    }
  }, [fetcher.data])

  const showSuggestions = term.trim() !== "" && matchingTags.length > 0

  return (
    <div className="absolute mt-2 p-2 lg:p-3 w-full bg-background border border-[border] rounded shadow-sm z-40">
      {showSuggestions ?
        <>
          <h3 className="font-medium mb-2">Suggestions</h3>
          <div className="flex items-center gap-2 flex-wrap">
            {matchingTags.map((tag) => (
              <Button key={tag} variant="outline" onClick={() => onSelectSuggestion(tag)}>
                {tag}
              </Button>
            ))}
          </div>
          <div className="mt-1 flex justify-center">
            <Button variant="link" className="text-primary">
              <a href="/the-licktionary-our-realworldsex-tags">Explore All Tags</a>
              <ChevronRightIcon className="w-4 h-4 text-primary" />
            </Button>
          </div>
        </>
      : <>
          <h3 className="font-medium mb-2">Popular Searches </h3>
          {FeaturedTags.map((category) => (
            <div key={category.label} className="mb-2">
              <h4 className="font-medium mb-1">{category.label}</h4>
              <div className="flex items-center gap-2 flex-wrap">
                {category.tags.map((tag) => (
                  <Button key={tag} variant="outline" onClick={() => onSelectSuggestion(tag)}>
                    {tag}
                  </Button>
                ))}
              </div>
            </div>
          ))}
          <div className="mt-2 flex justify-center">
            <Button variant="link" className="text-primary">
              <a href="/the-licktionary-our-realworldsex-tags">Explore All Tags</a>
              <ChevronRightIcon className="w-4 h-4 text-primary" />
            </Button>
          </div>
        </>
      }
    </div>
  )
}
