import { Link } from "@remix-run/react"
import { cn } from "~/utils/shadecn"

interface HeaderNavLinkProps {
  to: string
  children: React.ReactNode
  reloadDocument?: boolean
  className?: string
}

export function HeaderNavLink({ to, children, reloadDocument, className }: HeaderNavLinkProps) {
  return (
    <Link
      to={to}
      reloadDocument={reloadDocument}
      className={cn(
        "text-sm font-semibold text-nowrap relative before:content-[''] before:absolute before:top-1/2 before:-translate-y-1/2",
        "before:left-[-12px] before:w-[6px] before:h-[6px] before:bg-primary before:rounded-[50%]",
        "before:transition-all before:duration-300 before:ease-in-out",
        "hover:before:scale-125 hover:before:blur-[0.75px] hover:before:bg-primary/80",
        className
      )}>
      {children}
    </Link>
  )
}
