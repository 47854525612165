import { useRef, useState, useEffect } from "react"

export function useClickOutside<T extends HTMLElement, U extends HTMLElement>() {
  const [isOpen, setIsOpen] = useState(false)
  const inputRef = useRef<T>(null)
  const contentRef = useRef<U>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      contentRef.current &&
      !contentRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  const handleMousedownInside = (event: MouseEvent) => {
    if (contentRef.current && contentRef.current.contains(event.target as Node)) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    if (!isOpen) return;
    document.addEventListener("mousedown", handleClickOutside)

    const contentElement = contentRef.current
    if (contentElement) {
      contentElement.addEventListener("mousedown", handleMousedownInside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      if (contentElement) {
        contentElement.removeEventListener("mousedown", handleMousedownInside)
      }
    }
  }, [isOpen])

  return { isOpen, setIsOpen, inputRef, contentRef }
}
