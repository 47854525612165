import React, { useEffect, useState, useRef } from "react"
import logo from "~/assets/logo.webp"
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar"
import { Button } from "~/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu"
import { Switch } from "~/components/ui/switch"
import { cn } from "~/utils/shadecn"
import { Theme } from "remix-themes"
import { HamburgerMenuIcon, MagnifyingGlassIcon, PlayIcon, Cross2Icon } from "@radix-ui/react-icons"
import { BellIcon, BookOpenIcon, HeartIcon } from "@heroicons/react/24/outline"
import { BellIcon as SolidBellIcon } from "@heroicons/react/24/solid"
import { Form, Link, useSearchParams, useFetcher, redirect } from "@remix-run/react"
import { Sheet, SheetContent, SheetTrigger } from "~/components/ui/sheet"
import { FeedCardSmall } from "~/components/cards"
import { type loader as notificationsLoader } from "~/routes/_app+/happy-place+/feed"
import Typography from "~/components/typography"
import { HeaderNavLink } from "~/components/ui/nav-links"
import { SearchPreview } from "~/components/search/search-preview"
import { useClickOutside } from "~/utils/hooks/click-outside"

export default function Nav({
  user,
  avatar,
  notificationCount,
  subCredits,
  bundleCredits,
  theme,
  setTheme,
  hideUser,
  reloadDocument,
}: {
  user?: any
  avatar: string | undefined
  notificationCount: number
  subCredits: number
  bundleCredits: number
  theme?: Theme
  setTheme?: (theme: Theme) => void
  hideUser?: boolean
  reloadDocument?: boolean
}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [searchParams] = useSearchParams()
  const [term, setTerm] = useState("")
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [newNotificationCount, setNewNotificationCount] = useState(notificationCount)
  const navRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const {
    isOpen: isSearchPreviewOpen,
    setIsOpen: setIsSearchPreviewOpen,
    inputRef,
    contentRef,
  } = useClickOutside<HTMLInputElement, HTMLDivElement>()

  useEffect(() => {
    if (isSearchOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isSearchOpen, inputRef])

  useEffect(() => {
    setTerm(searchParams?.get("term") || "")
  }, [searchParams])

  const onSearchChange = (e: any) => {
    setTerm(e.target.value)
  }

  const onSelectSearchSuggestion = (suggestion: string) => {
    setTerm(suggestion)
    setIsSearchPreviewOpen(false)
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.submit()
      }
    }, 0)
  }

  const onOpenSearch = () => {
    setIsSearchOpen(true)
  }

  const onCloseSearch = () => {
    if (formRef.current && term.trim() !== "") {
      setTimeout(() => setIsSearchOpen(false), 100)
    } 
  }

  useEffect(() => {
    if (!isSearchOpen) return
    const handleNavClickOutside = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setIsSearchOpen(false)
      }
    }
    document.addEventListener("mousedown", handleNavClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleNavClickOutside)
    }
  }, [isSearchOpen])

  return (
    <div
      ref={navRef}
      id="nav"
      className="w-full backdrop-blur-lg data-[menu-open=true]:backdrop-blur-xl backdrop-saturate-150 bg-gradient-to-b from-background/50 to-background/30 fixed top-0 inset-x-0 z-30">
      <div className="flex h-12 sm:h-16 items-center mr-2 sm:mx-2 lg:mx-12 relative">
        <Sheet open={isMenuOpen} onOpenChange={setIsMenuOpen}>
          <SheetTrigger asChild>
            <Button className="lg:hidden border-none sm:mr-2 flex-none" size="icon" variant="outline">
              <HamburgerMenuIcon className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="flex flex-col gap-4">
            <div className="space-y-8 mt-9 mr-4">
              <Link
                to="/videos"
                reloadDocument={reloadDocument}
                onClick={() => setIsMenuOpen(false)}
                className="block">
                <div className="grid grid-cols-[3rem_1fr] gap-4">
                  <div className="flex justify-center items-start">
                    <PlayIcon className="w-8 h-8 text-primary" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold hover:underline hover:text-zinc-900 dark:hover:text-zinc-200">
                      Watch
                    </h3>
                    <p className="text-sm text-zinc-500 dark:text-zinc-400">Explore videos and stars</p>
                  </div>
                </div>
              </Link>
              <Link
                to="/videos/create"
                reloadDocument={reloadDocument}
                onClick={() => setIsMenuOpen(false)}
                className="block">
                <div className="grid grid-cols-[3rem_1fr] gap-4">
                  <div className="flex justify-center items-start">
                    <HeartIcon className="w-8 h-8 text-primary"/>
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold hover:underline hover:text-zinc-900 dark:hover:text-zinc-200">
                      Share
                    </h3>
                    <p className="text-sm text-zinc-500 dark:text-zinc-400">
                      Show the world what real world sex means to you
                    </p>
                  </div>
                </div>
              </Link>
              <Link
                to="/learn-more"
                reloadDocument={reloadDocument}
                onClick={() => setIsMenuOpen(false)}
                className="block">
                <div className="grid grid-cols-[3rem_1fr] gap-4">
                  <div className="flex justify-center items-start">
                    <BookOpenIcon className="w-8 h-8 text-primary" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-semibold hover:underline hover:text-zinc-900 dark:hover:text-zinc-200">
                      Learn More
                    </h3>
                    <p className="text-sm text-zinc-500 dark:text-zinc-400">Learn all about the social sex revolution</p>
                  </div>
                </div>
              </Link>
            </div>
          </SheetContent>
        </Sheet>
        <div className={cn("block", isSearchOpen && "hidden lg:block")}>
          <Link
            to="/"
            reloadDocument={reloadDocument}
            className="block text-left flex flex-col flex-none justify-center item-start bg-primary h-12 sm:h-16 mr-4 rounded-md">
            <img
              className="block px-2 sm:px-4 lg:px-4 w-24 sm:w-40"
              style={{ height: "auto" }}
              src={logo}
              alt="Make Love Not Porn"
            />
            <span className="block pl-4 mt-[-1px] sm:mt-[-4px] font-tight text-white text-[0.4rem] sm:text-[0.70rem]">
              MakeLoveNotPorn
            </span>
          </Link>
        </div>
        <div
          className={cn(
            "absolute left-1/2 -translate-x-1/2 flex items-center space-x-1 sm:space-x-8 gap-4 hidden",
            !isSearchOpen && "lg:block"
          )}>
          <HeaderNavLink to="/videos" reloadDocument={reloadDocument}>
            Watch
          </HeaderNavLink>
          <HeaderNavLink to="/videos/create" reloadDocument={reloadDocument}>
            Share
          </HeaderNavLink>
          <HeaderNavLink to="/learn-more" reloadDocument={reloadDocument}>
            Learn More
          </HeaderNavLink>
        </div>
        <div className="flex items-center space-x-1 sm:space-x-4 absolute right-0">
          <div
            className={cn("group flex items-center cursor-pointer", isSearchOpen && "hidden")}
            onClick={onOpenSearch}>
            <MagnifyingGlassIcon className={cn("h-6 w-6 md:group-hover:mr-1")} />
            <span className="hidden md:group-hover:block text-xs">Search Videos or Stars</span>
          </div>
          {isSearchOpen && (
            <div className="fixed inset-0 z-50 h-12 sm:h-16" style={{ pointerEvents: "none" }}>
              <div className="h-full flex items-center justify-center">
                <div className="flex items-center gap-2 p-4" style={{ pointerEvents: "auto" }}>
                  <div ref={contentRef} className="relative">
                  <Form ref={formRef} action={term.trim() !== "" ?  "/videos/search" : "/videos"} method="get" className="relative">
                      <input
                        ref={inputRef}
                        name="term"
                        placeholder="Search for Videos or Stars"
                        type="text"
                        value={term}
                        autoComplete="off"
                        onChange={onSearchChange}
                        onFocus={() => setIsSearchPreviewOpen(true)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onCloseSearch();
                          }
                        }}
                        className={cn(
                          "w-60 sm:w-72 md:w-96 lg:w-[28rem] h-9 px-3 rounded-md border border-muted bg-background/70 focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary text-foreground !text-[14px]"
                        )}
                      />
                      <Button
                        type="submit"
                        disabled={term.trim() === ""}
                        className={cn("absolute right-0 h-full w-4 rounded-l-none bg-transparent")}>
                        <MagnifyingGlassIcon className="absolute h-5 w-5 text-black" />
                      </Button>
                    </Form>
                    {isSearchPreviewOpen && (
                      <div ref={contentRef}>
                        <SearchPreview term={term} onSelectSuggestion={onSelectSearchSuggestion} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="absolute right-4 md:right-12" style={{ pointerEvents: "auto" }}>
                  <Button variant="default" className="p-2" onClick={() => setIsSearchOpen(false)}>
                    <Cross2Icon className="md:w-6 md:h-6 w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          {!hideUser && !isSearchOpen && (
            <>
              {user && subCredits + bundleCredits > 0 && (
                <p className="text-xs bg-foreground/10 px-2 py-1 rounded-lg text-nowrap">
                  <Link to="/billing">Credits: {subCredits + bundleCredits}</Link>
                </p>
              )}
              {user ?
                <>
                  <Notifications user={user} newCount={newNotificationCount} setNewCount={setNewNotificationCount} />

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                        <Avatar className="h-8 w-8">
                          <AvatarImage src={avatar} className="object-cover" alt={user.UserName} />
                          <AvatarFallback>{user.UserName}</AvatarFallback>
                        </Avatar>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56" align="end" forceMount>
                      <DropdownMenuLabel className="font-normal">
                        <div className="flex flex-col space-y-1">
                          <p className="text-sm font-medium leading-none">{user.DisplayName || user.UserName}</p>
                          <p className="text-xs leading-none text-muted-foreground">{user.Email}</p>
                        </div>
                        {setTheme && (
                          <div className="flex justify-start items-center mt-2 gap-2">
                            <p>Dark mode</p>
                            <Switch
                              checked={theme === Theme.DARK}
                              onCheckedChange={(dark) => {
                                setTheme(dark ? Theme.DARK : Theme.LIGHT)
                              }}
                            />
                          </div>
                        )}
                      </DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuGroup>
                        <DropdownMenuItem>
                          <Link className="w-full hover:underline" to="/refer-a-friend" reloadDocument={reloadDocument}>
                            Refer A Friend
                          </Link>
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                      <DropdownMenuSeparator />
                      <DropdownMenuGroup>
                        <DropdownMenuItem>
                          <Link className="w-full hover:underline" to="/happy-place" reloadDocument={reloadDocument}>
                            Happy Place
                          </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Link className="w-full hover:underline" to="/dashboard" reloadDocument={reloadDocument}>
                            Your Videos
                          </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Link className="w-full hover:underline" to="/settings" reloadDocument={reloadDocument}>
                            Settings
                          </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Link className="w-full hover:underline" to="/billing" reloadDocument={reloadDocument}>
                            Billing
                          </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                          <Link className="w-full hover:underline" to="/profile" reloadDocument={reloadDocument}>
                            Profile
                          </Link>
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem asChild>
                        <Form action="/logout" method="post">
                          <Button variant="link" type="submit" className="button p-0 h-auto w-full justify-start">
                            Logout
                          </Button>
                        </Form>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </>
              : <div className="ml-auto flex items-center space-x-2 sm:space-x-4">
                  <Link to="/login" reloadDocument={reloadDocument} className="text-xs sm:text-base text-nowrap">
                    Sign in
                  </Link>
                  <Link to="/register" reloadDocument={reloadDocument}>
                    <Button className="h-6 sm:h-9 p-2 sm:p-4 text-xs sm:text-base rounded-sm sm:rounded-md">
                      <div className="block sm:hidden">Sign up</div>
                      <div className="hidden sm:block">Create Account</div>
                    </Button>
                  </Link>
                </div>
              }
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const Notifications = ({
  user,
  newCount,
  setNewCount,
}: {
  user: any
  newCount: any
  setNewCount: (count: number) => void
}) => {
  const [loading, setLoading] = useState(false)
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [notifications, setNotifications] = useState<any[]>([])
  const fetcher = useFetcher<typeof notificationsLoader>()

  useEffect(() => {
    if (isNotificationsOpen) {
      const formData = new FormData()
      formData.append("limit", "10")
      formData.append("accountNotifications", "true")
      fetcher.submit(formData, {
        action: "/happy-place/feed",
        method: "GET",
      })
    }
  }, [isNotificationsOpen])

  useEffect(() => {
    setLoading(fetcher.state === "submitting")
    if (fetcher.data) {
      setNotifications(fetcher.data.items)
      setNewCount(0)
    }
  }, [fetcher.state])

  return (
    <Sheet open={isNotificationsOpen} onOpenChange={setIsNotificationsOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="relative hover:bg-background/30 dark:hover:bg-background/20 mr-1">
          {newCount > 0 ?
            <>
              <SolidBellIcon className="h-[1.5rem] w-[1.5rem]" />
              <div
                className={cn(
                  "absolute bg-primary text-white top-[1px] right-[2px] text-[0.8rem] leading-4 rounded-lg px-[0.2rem]",
                  newCount >= 10 && "!leading-[0.8rem] px-[0.1rem] text-[0.5rem]"
                )}>
                {newCount >= 10 ? "10+" : newCount}
              </div>
            </>
          : <BellIcon className="h-[1.5rem] w-[1.5rem]" />}
          <span className="sr-only">Notifications</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="flex flex-col gap-4 overflow-auto max-h-screen">
        <div>
          <h3 className="text-sm font-semibold">Notifications</h3>
          {loading && <div>Loading...</div>}
          <div className="mt-8 mx-auto space-y-4 max-w-lg pb-24 md:max-w-2xl">
            {notifications &&
              notifications.map((post, index) => (
                <FeedCardSmall key={index} post={post} open={isNotificationsOpen} setOpen={setIsNotificationsOpen} />
              ))}
            {notifications && notifications.length >= 10 && (
              <Typography className="my-24">
                <Link
                  to={{
                    pathname: "/happy-place/feed",
                    search: "?limit-10&accountNotifications=true",
                  }}
                  onClick={() => setIsNotificationsOpen(false)}
                  className="block text-center text-sm font-medium text-accent hover:underline">
                  View All Notifications
                </Link>
              </Typography>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
