export const FeaturedTags: { label: string; tags: string[] }[] = [
    {
      label: "Activities",
      tags: ["blowjob", "frombehind", "clitastic", "roleplay"],
    },
    {
      label: "Locations",
      tags: ["showertime", "inthesauna", "bythepool", "vacationtime"],
    },
    {
      label: "Vibes",
      tags: ["giggly", "tender", "romantic", "ravenous"],
    },
  ]
